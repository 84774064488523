import {useState} from "react";
import {Select, SelectItem} from "@nextui-org/react";
import InputNext from "../../styled-components/InputNext";


function PersonalInformation({formState, setFormState}) {
    const [listaSexo, setListaSexto] = useState(new Set([formState?.individual?.sexo || '']));

    const types = ["Hombre", "Mujer"];

    const handleSelectionChange = (e) => {
        setListaSexto(new Set([e.target.value]));
        setFormState({
            ...formState.individual,
            sexo: e.target.value
        });
    };


    return (
        <>
            <InputNext
                defaultValue={formState?.individual?.name}
                type="text"
                isRequired
                variant="flat"
                label="Nombre y Apellido"
                onChange={({target}) => {
                    setFormState({
                        ...formState.individual,
                        name: target.value,
                    });
                }}
            />
            <InputNext
                defaultValue={formState?.individual?.ci}
                type="number"
                isRequired
                variant="flat"
                label="CI"
                onChange={({target}) => {
                    setFormState({...formState.individual, ci: target.value});
                }}
            />
            <InputNext
                defaultValue={formState?.individual?.edad}
                type="number"
                isRequired
                variant="flat"
                label="Edad"
                onChange={({target}) => {
                    setFormState({
                        ...formState.individual,
                        edad: target.value,
                    });
                }}
            />

            <Select
                isRequired
                label="Sexo"
                className="max-w-xs"
                onChange={handleSelectionChange}
                // defaultSelectedKeys={[formState?.individual?.sexo]}
                selectedKeys={listaSexo}
            >
                {types.map((sexo) => (
                    <SelectItem key={sexo} value={sexo}>
                        {sexo}
                    </SelectItem>
                ))}
            </Select>
            <InputNext
                defaultValue={formState?.individual?.celular}
                type="number"
                isRequired
                variant="flat"
                label="Celular"
                onChange={({target}) => {
                    setFormState({
                        ...formState.individual,
                        celular: target.value,
                    });
                }}
            />
        </>
    );
}

export default PersonalInformation;
