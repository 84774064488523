import styled from "styled-components";

const InscriptionCenter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #e5c288;
  margin-top: 15px;
`;

export default InscriptionCenter;
