import styled from "styled-components";

const InscriptionCenterQuantityContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 5px;
  margin-bottom: 20px;
`;

export default InscriptionCenterQuantityContainer;
