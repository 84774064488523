export const ANDROID = "Android";
export const IOS = "iOS";
export const UNKNOWN = "unknown";

export function isIOS(os) {
  return os === IOS;
}

export function isAndroid(os) {
  return os === ANDROID;
}

export function getMobileOperatingSystem() {
  const userAgent = navigator.userAgent || navigator.vendor;

  if (/android/i.test(userAgent)) {
    return ANDROID;
  }

  if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
    return IOS;
  }

  return UNKNOWN;
}
