import styled from 'styled-components';

export const LinksContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
`;

export const LinkSpan = styled.span`
  cursor: pointer;
  color: #ffffffed;
  margin-top: 15px;
`;

export const LinkAnchor = styled.a`
  cursor: pointer;
  color: #fff;
  margin-top: 15px;
`;

export const LinkIcon = styled.a`
  cursor: pointer;
  color: #fff;
  margin-top: 20px;
  margin-right: 20px;
`;
