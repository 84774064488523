import { GENERIC_ERROR } from "../../../utils/genericError";

import { collection, addDoc, Timestamp } from "firebase/firestore";
import { getData } from "../../../firebase";
import handlePrice from "./handlePrice";
import getTypeInscription from "./getTypeInscription";
import onValidInscriptionExist from "../services/onValidInscriptionExist";
import fetchTable from "../hooks/fetchTable";

export async function sendInscription(inscription, modeReinscription) {
  try {
    // const price = handlePrice(inscription);
    const type = getTypeInscription(inscription);
    const fetchTableOnInsert = fetchTable(inscription);

    const inscriptionExist = await onValidInscriptionExist(
      inscription,
      modeReinscription,
      fetchTableOnInsert,
    );

    if (!inscriptionExist) {
      const data = await addDoc(collection(getData(), fetchTableOnInsert), {
        ...inscription,
        date: Timestamp.fromDate(new Date()),
        price: 0,
        statusPayment: inscription?.statusPayment || false,
        modeReinscription: modeReinscription || false,
        type,
      });
      if (data.id) return { id: data.id /* price */ };
    } else {
      return false;
    }
  } catch (error) {
    return error || GENERIC_ERROR;
  }
}

export default sendInscription;
