import styled from "styled-components";

const FormContentContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-height: 50vh;
`;

export default FormContentContainer;
