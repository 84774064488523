import {Result, Button} from "antd";
import {Link} from "react-router-dom";
import {Card, CardBody} from "@nextui-org/react";

export default function SendFail({title, subTitle, buttonMessage}) {
    return (
        <Card>
            <CardBody>
                <Result
                    status="error"
                    title={title}
                    subTitle={subTitle}
                    extra={[
                        <Link to="/">
                            <Button type="primary" key="console">
                                {buttonMessage}
                            </Button>
                        </Link>,
                    ]}
                />
            </CardBody>
        </Card>

    );
}
