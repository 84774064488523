import {React} from "react";
import ShowFormBulkContainer from "./styled-components/ShowFormBulkContainer";
import {
    List,
    Avatar,
} from "antd";
import logo from "../../../../assets/icons/alfa-logo.png";
import TitleCountContainer from "./styled-components/TitleCountContainer";
import {
    Chip,
    Table,
    TableHeader,
    TableColumn,
    TableBody,
    TableRow,
    TableCell, Card, CardBody
} from "@nextui-org/react";
import IconMan from "../../../../assets/icons/man-icon.png";
import IconWomen from "../../../../assets/icons/women-icon.png";


const fakeDataUrl =
    "https://randomuser.me/api/?results=20&inc=name,gender,email,nat,picture&noinfo";
const ContainerHeight = 400;

function ShowResumeBulk({formState}) {
    console.log("QWEJKQWLE", formState.groupal);

    return (
        <div className="flex flex-col">
            <Card className="mb-4">
                <CardBody>
                    <TitleCountContainer>
                        <p className="text-lg font-semibold mr-2">
                            Responsable:
                        </p>
                        <p className="text-lg font-semibold mr-4 text-yellow-600">
                            {formState?.groupal[0]?.owner}
                        </p>
                        <p className="text-lg font-semibold">
                            Total:
                        </p>
                        <div className="flex justify-center">
                            <Chip
                                className="text-xl font-semibold ml-2"
                                color="success"
                                variant="solid"
                            >
                                {formState.groupal.length}
                            </Chip>
                        </div>
                    </TitleCountContainer>
                </CardBody>
            </Card>

            <ShowFormBulkContainer>
                <>
                    <Table
                        color="secondary"
                        selectionMode="single"
                        defaultSelectedKeys={[]}
                        aria-label="Inscriptos"
                    >
                        <TableHeader>
                            <TableColumn></TableColumn>
                            <TableColumn>Nombre</TableColumn>
                            <TableColumn>Edad</TableColumn>
                            <TableColumn>Sexo</TableColumn>
                            <TableColumn>Comentarios</TableColumn>
                        </TableHeader>
                        <TableBody>
                            {formState?.groupal?.length > 0 &&
                                formState.groupal.map((inscription, index) => (
                                        <TableRow className="text-black" key={index}>
                                            <TableCell>
                                                <Avatar
                                                    isBordered
                                                    color="primary"
                                                    radius="lg"
                                                    src={
                                                        inscription.sexo === "Hombre"
                                                            ? IconMan
                                                            : IconWomen
                                                    }
                                                /></TableCell>
                                            <TableCell>{inscription.name}</TableCell>
                                            <TableCell>{inscription.edad}</TableCell>
                                            <TableCell>{inscription.sexo}</TableCell>
                                            <TableCell>{inscription.comment}</TableCell>
                                        </TableRow>
                                    )
                                )
                            }
                        </TableBody>
                    </Table>


                </>
            </ShowFormBulkContainer>
        </div>
    );
}

export default ShowResumeBulk;
