import {React, useState} from "react";
import {TYPE_INSCRIPTION} from "../../constants";
import {Steps} from "antd";

import Individual from "../Individual";
import removeDaysFromInscription from "../../hooks/removeDaysFromInscription";
import Bulk from "../Bulk";
import {Card, CardBody, Chip, Divider} from "@nextui-org/react";
import Placa from "../../../../assets/images/alfa2024placa.png";

function Wizard({type, formState, modeReinscription}) {
    const finalStep = 3;
    const [step, setStep] = useState(0);
    let formStateData = formState;

    if (modeReinscription) {
        formStateData = removeDaysFromInscription(formState);
    }

    const handleStep = (step) => {
        setStep(step);
    };

    const getVariant = (stepNow) => {
        return stepNow === step ? 'flat' : 'variant'
    }

    const {Step} = Steps;
    return (
        <div className="p-4 md:max-w-7xl md:w-full overflow-x-hidden md:pt-12 h-full">
            <Card style={{
                background: "linear-gradient(224deg, rgb(174 117 95) 0%, rgb(235 188 101) 100%)",
                height: "100%"
            }}
                  className="">
                <CardBody>
                    {type === TYPE_INSCRIPTION.GROUP && (
                        <div className="h-full">
                            <div>
                                <Steps className="mb-8" size="small" responsive progressDot={false} current={step}>
                                    <Step title="Carga archivo" description=""/>
                                    <Step title="Detalles" description=""/>
                                    <Step title="Confirmación" description="Validá tus datos"/>
                                </Steps>
                                <Divider/>
                            </div>
                            <Bulk
                                currentStep={step}
                                setStep={handleStep}
                                formState={formStateData}
                                modeReinscription={modeReinscription}
                            />
                        </div>
                    )}
                    {type === TYPE_INSCRIPTION.INDIVIDUAL && (
                        <div className="flex">
                            <div className="md:px-8 w-full md:w-2/3 md:pr-4">
                                <div className="flex justify-center items-center">
                                    <Card className="flex flex-row">
                                        {step !== finalStep &&
                                            <>
                                                <CardBody>
                                                    <Chip color="warning" variant={getVariant(0)} size="lg">Paso
                                                        1</Chip>
                                                </CardBody>
                                                <CardBody>
                                                    <Chip color="warning" variant={getVariant(1)} size="lg">Paso
                                                        2</Chip>
                                                </CardBody>
                                                <CardBody>
                                                    <Chip color="warning" variant={getVariant(2)} size="lg">Paso
                                                        3</Chip>
                                                </CardBody>
                                            </>
                                        }
                                        {step === finalStep &&
                                            <CardBody className="items-center">
                                                <Chip color="warning" variant={"flat"} size="lg">Resumen</Chip>
                                            </CardBody>
                                        }
                                    </Card>
                                </div>
                                <Individual
                                    currentStep={step}
                                    setStep={handleStep}
                                    formState={formStateData}
                                    modeReinscription={modeReinscription}
                                />
                            </div>
                            <div className="invisible w-0 md:w-auto md:visible">
                                <img src={Placa} alt="placa" width={400}/>
                            </div>
                        </div>
                    )}
                </CardBody>
            </Card>
        </div>
    );
}

export default Wizard;
