import { collection, query, where, getDocs } from "firebase/firestore";
import { getData } from "../../../firebase";

const getInscriptionByCi = async (ci, table) => {
  let data;
  const qInd = query(
    collection(getData(), table),
    where("individual.ci", "==", ci)
  );

  const querySnapshotInd = await getDocs(qInd);
  const ind = querySnapshotInd._snapshot.docChanges.length > 0;

  const qMatFather = query(
    collection(getData(), table),
    where("father.ci", "==", ci)
  );

  const querySnapshotMatFather = await getDocs(qMatFather);
  const matFather = querySnapshotMatFather._snapshot.docChanges.length > 0;

  const qMatMother = query(
    collection(getData(), table),
    where("mother.ci", "==", ci)
  );

  const querySnapshotMatMother = await getDocs(qMatMother);
  const matMother = querySnapshotMatMother._snapshot.docChanges.length > 0;

  let querySnapshot;

  if (ind) querySnapshot = querySnapshotInd;
  if (matFather) querySnapshot = querySnapshotMatFather;
  if (matMother) querySnapshot = querySnapshotMatMother;

  if (querySnapshot) {
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      console.log(doc.id, " => ", doc.data());
      if (doc.id)
        data = {
          ...doc.data(),
          id: doc.id,
        };
    });
  }

  if (data) {
    console.log("Encontrado:", data);
    return data;
  } else {
    console.log("No such document");
    return false;
  }
};

export default getInscriptionByCi;
