import React from "react";
import {Chip, RadioGroup} from "@nextui-org/react";
import CustomRadio from "../../../../../../../../components/CustomRadio";
import {BUS_OPTIONS} from "../../../../../../constants";
import styled from "styled-components";

const RadioGroupStyled = styled(RadioGroup)`
 && span {
    color: #11181c;
 }
`

function RadioGroupBus({onChangeRadioGroup, disabled, valueSelected}) {
    return (
        <RadioGroupStyled
            label="Bus"
            size="sm"
            onChange={({target}) => onChangeRadioGroup([target.defaultValue])}
            color="warning"
            isDisabled={disabled}
            value={valueSelected}
            className="my-3"
            isRequired
        >
            {
                !disabled &&
                BUS_OPTIONS?.map((bus) => (
                    <CustomRadio value={bus.value}>
                        {bus.name}
                    </CustomRadio>
                ))
            }
            {disabled &&
                <>
                    <CustomRadio value="No disponible">
                        No disponible
                    </CustomRadio>
                    <div className="flex justify-end mr-6">
                    <Chip color="danger">Bus sin lugares. Hablar con Ileana (+59899375371).</Chip>
                    </div>
                </>
            }
        </RadioGroupStyled>
    );
}

export default RadioGroupBus;
