import styled from "styled-components";
import {device} from "../../../utils/device";

const FooterContainer = styled.div`
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin-bottom: 0px;
  // @media ${device.tablet} {
  //   margin-top: 40px;
  // }
  // margin-top: 400px;
  display: flex;
  align-items: center;
  align-content: center;
  background: linear-gradient(224deg, rgb(174 117 95) 0%, rgb(235 188 101) 100%);
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  min-height: 20%;
`;

export default FooterContainer;
