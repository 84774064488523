import React from "react";
import Container from "./styled-components/Container";
import {LinksContainer, LinkSpan} from "./styled-components/LinksContainer";
import CardWidget from "../CardWidget/CardWidget";
import Logo from "../../assets/icons/alfa-logo.png";
import FooterContainer from "./styled-components/FooterContainer";
import SectionContainer from "./styled-components/SectionContainer";
import {Link} from "react-router-dom";

const Footer = () => {
    return (
        <FooterContainer>
            <Container>
                <SectionContainer>
                    {/*<CardWidget icon={Logo} />*/}
                    <LinksContainer>
                        <LinkSpan>© Campamento Cristiano Alfa {new Date().getFullYear()}.</LinkSpan>
                        {/* <Link to="/gestion">
            </Link> */}
                    </LinksContainer>
                </SectionContainer>
            </Container>
        </FooterContainer>
    );
};

Footer.propTypes = {};

export default Footer;
