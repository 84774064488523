import Img01 from "../../assets/images/galery/galery-01.jpg";
import Img02 from "../../assets/images/galery/galery-02.jpg";
import Img03 from "../../assets/images/galery/galery-03.jpg";
import Img04 from "../../assets/images/galery/galery-04.jpg";
import Img05 from "../../assets/images/galery/galery-05.jpg";
import Img06 from "../../assets/images/galery/galery-06.jpg";
import Img07 from "../../assets/images/galery/galery-07.jpg";
import Img08 from "../../assets/images/galery/galery-08.jpg";
import Img09 from "../../assets/images/galery/galery-09.jpg";
import Img10 from "../../assets/images/galery/galery-10.jpg";

export const IMAGES = [
  Img01,
  Img02,
  Img03,
  Img04,
  Img05,
  Img06,
  Img07,
  Img08,
  Img09,
  Img10
];
