import styled from "styled-components";

const DescriptionContainer = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;
  width: 70%;
`;

export default DescriptionContainer;
