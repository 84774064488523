import { SEDE, TYPE_INSCRIPTION } from "../constants";
import getTypeInscription from "./getTypeInscription";

const removeDaysFromInscription = (inscription) => {
  let inscriptionResponse;
  if (getTypeInscription(inscription) === TYPE_INSCRIPTION.INDIVIDUAL) {
    inscriptionResponse = {
      ...inscription,
      individual: {
        ...inscription.individual,
        dias: "",
      },
    };
  }

  if (getTypeInscription(inscription) === TYPE_INSCRIPTION.MATRIMONIAL) {
    inscriptionResponse = {
      ...inscription,
      father: {
        ...inscription.father,
        dias: "",
      },
      mother: {
        ...inscription.mother,
        dias: "",
      },
      children: {
        ...inscription.children,
        dias: "",
      },
    };
  }

  return inscriptionResponse;
};

export default removeDaysFromInscription;
