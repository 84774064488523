import styled from "styled-components";

const ShowFormBulkContainer = styled.div`
  color: white;
  width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
  max-height: 400px;
  margin-bottom: 15px;
`;

export default ShowFormBulkContainer;
