import styled from "styled-components";

const ButtonSelect = styled.button`
  margin: 10px;
  width: 100%;
  height: 45px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-style: none;
  border-radius: 6px;
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
  background-color: #ffc314;
  color: rgba(0, 0, 0, 0.85);
  font-size: 0.85em;
  font-weight: bold;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding: 20px;
  cursor: pointer;
`;

export default ButtonSelect;
