import React, {useEffect, useState} from "react";
import {Modal, ModalContent, ModalHeader, ModalBody, ModalFooter, Skeleton} from "@nextui-org/react";
import TitleModal from "./styled-components/TitleModal";
import ImageExample from "../../../../../../assets/images/example_load_file.png"
import ContainerModalContent from "./styled-components/ContainerModalContent";


const ExampleFileModal = ({visible, onCloseModal}) => {
    const [fakeLoader, setFakeLoader] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setFakeLoader(true)
        }, 2000);
    }, []);


    return (
        <Modal backdrop="blur" isOpen={visible} onClose={onCloseModal} size="3xl">
            <ModalContent>
                {(onClose) => (
                    <>
                        <ModalHeader className="flex flex-col gap-1">
                            <TitleModal>Ejemplo</TitleModal>
                        </ModalHeader>
                        <ModalBody>
                            <Skeleton isLoaded={fakeLoader} className="rounded-lg h-60">
                                <ContainerModalContent>
                                    <img src={ImageExample} alt="example"/>
                                </ContainerModalContent>
                            </Skeleton>

                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                    </>
                )}
            </ModalContent>
        </Modal>
    );
};

export default ExampleFileModal;
