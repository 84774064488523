import {createGlobalStyle} from "styled-components";

// language=LESS
const GlobalStyle = createGlobalStyle`

  html,
  body {
    font-family: 'Poppins', serif;
    font-size: 14px;
    height: 100%;
    padding: 0;
    margin: 0 !important;
    background: linear-gradient(161.91deg, rgb(231, 201, 158) 4.96%, rgb(181, 159, 97) 83.92%);

    button:focus,
    input:focus,
    textArea:focus,
    a:focus {
      outline: none;
    }

    & a,
    a:hover {
      text-decoration: none;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
      background-color: #ffffff !important;
    }

    input:-webkit-autofill,
    textarea:-webkit-autofill,
    select:-webkit-autofill {
      -webkit-box-shadow: 0 0 0px 1000px white inset;
    }

    .modal__overlay {
      background-color: rgba(39, 50, 57, 0.3);
    }

  }

  #alfa-app {
    height: 100%;
    width: 100%;
  }

  .ReactModal__Body--open #courier-app {
    filter: blur(2px);
  }


  .hide-info .gm-style-iw-a {
    display: none;
  }

  /* custom scrollbar */
  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background: transparent;
  }

  ::-webkit-scrollbar-thumb {
    background: rgb(176, 176, 176);
    border-radius: 5px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: rgb(176, 176, 176);
  }
`;

/* #alfa-app {
    height: 100%;
    width: 100%;
  }
`; */

export default GlobalStyle;
