import React from "react";
import {Button} from "@nextui-org/react";

function CardSelectionType({image, onClick, title, subtitle, actionText}) {
    return (
        <div onClick={onClick}>
            <a
                className="flex flex-col items-center bg-white border
                 border-gray-200 rounded-lg shadow md:flex-row md:max-w-xl
                 hover:bg-gray-100 dark:border-gray-700 dark:bg-gray-800 dark:hover:bg-gray-700 mb-8">
                <img
                    className="object-cover w-full rounded-t-lg h-40 md:h-auto md:w-48 md:rounded-none md:rounded-l-lg"
                    src={image} alt=""
                />
                <div className="flex flex-col justify-between p-4 leading-normal md:w-1/2">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">
                        {title}
                    </h5>
                    <p className="mb-3 font-normal text-gray-700 dark:text-gray-400">
                        {subtitle}
                    </p>
                </div>
                <Button
                    color="success"
                    radius="full"
                    size="md"
                    onClick={onClick}
                    className="p-4 mb-8 mr-0 md:mb-0 md:mr-4"
                >
                    {actionText}
                </Button>
            </a>
        </div>
    );
}

export default CardSelectionType;
