import { React, useState } from "react";
import { CAMP_BD } from "../../../inscription/constants";
import InscriptionCenter from "../../InscriptionCenter";
import ButtonSelect from "./styled-components/ButtonSelect";
import { Typography } from "antd";

import SelectTableContainer from "./styled-components/SelectTableContainer";

const { Title } = Typography;

function SelectTable() {
  const [tableSelected, setTableSelected] = useState("");

  const handleOnClickTable1 = () => {
    setTableSelected(CAMP_BD.CAMP_1);
  };
  const handleOnClickTable2 = () => {
    setTableSelected(CAMP_BD.CAMP_2);
  };
  const handleOnClickTable3 = () => {
    setTableSelected(CAMP_BD.CAMP_3);
  };
  const handleOnClickTable4 = () => {
    setTableSelected(CAMP_BD.CAMP_4);
  };
  const handleOnClickTable5 = () => {
    setTableSelected(CAMP_BD.CAMP_5);
  };
  const handleOnClickTable6 = () => {
    setTableSelected(CAMP_BD.CAMP_6);
  };
  const handleOnClickTable7 = () => {
    setTableSelected(CAMP_BD.CAMP_7);
  };
  const handleOnClickTable8 = () => {
    setTableSelected(CAMP_BD.CAMP_8);
  };
  const handleOnResetTable = () => {
    setTableSelected("");
  };

  return (
    <SelectTableContainer>
      {!tableSelected ? (
        <div>
          <Title level={3}> Campamento a filtrar:</Title>
          <ButtonSelect onClick={handleOnClickTable1}>Niños</ButtonSelect>
          <ButtonSelect onClick={handleOnClickTable2}>Pre</ButtonSelect>
          <ButtonSelect onClick={handleOnClickTable3}>Adol A</ButtonSelect>
          <ButtonSelect onClick={handleOnClickTable4}>Adol B</ButtonSelect>
          <ButtonSelect onClick={handleOnClickTable5}>Jov A</ButtonSelect>
          <ButtonSelect onClick={handleOnClickTable6}>Jov B</ButtonSelect>
          <ButtonSelect onClick={handleOnClickTable7}>Antiestrés</ButtonSelect>
          <ButtonSelect onClick={handleOnClickTable8}>Familias</ButtonSelect>
        </div>
      ) : (
        <InscriptionCenter
          camp={tableSelected}
          resetTableSelected={handleOnResetTable}
        ></InscriptionCenter>
      )}
    </SelectTableContainer>
  );
}

export default SelectTable;
