import {React, useEffect, useState} from "react";
import InscriptionCenterContainer from "./styled-components/InscriptionCenterContainer";
import {collection, getDocs} from "firebase/firestore";
import {getData} from "../../firebase";
import {Table, Statistic, Skeleton} from "antd";
import {
    CAMP_BD,
    MODE_INSCRIPTION,
    TYPE_INSCRIPTION,
} from "../inscription/constants";
import icon from "../../assets/icons/left-arrow.png";
import {Typography} from "antd";
import {columnsIndividual, columnsMixed} from "./utils/columns";
import InscriptionCenterQuantityContainer from "./styled-components/InscriptionCenterQuantityContainer";
import {CSVLink} from "react-csv";
import ButtonSelect from "./styled-components/ButtonSelect";
import TopContainer from "./styled-components/TopContainer";
import IconBackButton from "./styled-components/IconBackButton";
import BackButtonContainer from "./styled-components/BackButtonContainer";
import TitleUpdateContainer from "./styled-components/TitleUpdateContainer";
import {Card, CardBody, CardHeader} from "@nextui-org/react";

const {Title} = Typography;

function InscriptionCenter({camp, resetTableSelected}) {
    const [individualCamp, setIndividualCamp] = useState([]);
    const [individualLeader, setIndividualLeader] = useState([]);
    const [individualOther, setIndividualOther] = useState([]);
    const [busQuantityGoing, setBusQuantityGoing] = useState([]);
    const [busQuantityReturn, setbusQuantityReturn] = useState([]);
    const [mixedModeRows, setMixedModeRows] = useState([]);

    const [refresh, setRefresh] = useState(false);

    const isMixedMode =
        camp === CAMP_BD.CAMP_1 ||
        camp === CAMP_BD.CAMP_2 ||
        camp === CAMP_BD.CAMP_3 ||
        camp === CAMP_BD.CAMP_4;

    useEffect(() => {
        const modeCamp = isMixedMode ? mixedModeRows : individualCamp;
        const tableOnFilter = [
            ...modeCamp,
            ...individualLeader,
            ...individualOther,
        ];
        let busQuantityGoing = 0;
        let busQuantityReturn = 0;
        tableOnFilter.map((x) => {
            if (
                x.bus &&
                x.bus === "Ida y Vuelta" &&
                x.departure !== "Transporte propio"
            ) {
                busQuantityGoing++;
                busQuantityReturn++;
            }
            if (x.bus && x.bus === "Ida" && x.departure !== "Transporte propio") {
                busQuantityGoing++;
            }
            if (x.bus && x.bus === "Vuelta" && x.departure !== "Transporte propio") {
                busQuantityReturn++;
            }
        });
        setBusQuantityGoing(busQuantityGoing);
        setbusQuantityReturn(busQuantityReturn);
    }, [individualCamp, individualLeader, individualOther, mixedModeRows]);

    const hasRows =
        individualCamp.length > 0 ||
        individualLeader.length > 0 ||
        individualOther.length > 0 ||
        mixedModeRows.length > 0;

    const getRowsGroupalsIndividuals = (group, individual) => {
        return [...group, ...individual];
    };

    const handleOnClickUpdate = () => {
        setRefresh(!refresh);
    };

    useEffect(() => {
        const getInscriptos = async () => {
            const inscriptionColection = collection(getData(), `${camp}`);

            const inscriptionSnapshot = await getDocs(inscriptionColection);

            const inscriptionListF = inscriptionSnapshot.docs.map((doc) => ({
                id: doc.id,
                ...doc.data(),
            }));

            //Inscripcion por Excel.
            const resultGroupCamp = inscriptionListF.filter(
                (bookId) => bookId.type === TYPE_INSCRIPTION.GROUP
            );

            const mookGroupCamp = [];

            resultGroupCamp.forEach((group) => {
                group.groupal.forEach((element) => {
                    mookGroupCamp.push({
                        bus: element.bus,
                        camp: element.camp,
                        comment: element.comment,
                        departure: element.departure,
                        dias: element.dias,
                        edad: element.edad,
                        iglesia: element.iglesia,
                        name: element.name,
                        sexo: element.sexo,
                        mode: element.mode,
                        owner: element.owner,
                        date: group.date.toDate().toLocaleDateString("en-GB"),
                        dateOrder: group.date.toDate(),
                        id: group.id,
                    });
                });
            });

            const resultIndividualCamp = inscriptionListF.filter(
                (bookId) =>
                    bookId.type === TYPE_INSCRIPTION.INDIVIDUAL &&
                    bookId.individual.mode === MODE_INSCRIPTION.ACAMPANTE
            );

            const mookIndividualCamp = resultIndividualCamp.map((ind) => ({
                id: ind.id,
                ...ind.individual,
                date: ind.date.toDate().toLocaleDateString("en-GB"),
                dateOrder: ind.date.toDate(),
                departure: ind?.individual?.departure || "-",
                type: "INDIVIDUAL",
            }));

            const resultIndividualLeader = inscriptionListF.filter(
                (bookId) =>
                    bookId.type === TYPE_INSCRIPTION.INDIVIDUAL &&
                    bookId.individual.mode === MODE_INSCRIPTION.LIDER
            );

            const mookIndividualLeader = resultIndividualLeader.map((ind) => ({
                id: ind.id,
                ...ind.individual,
                date: ind.date.toDate().toLocaleDateString("en-GB"),
                dateOrder: ind.date.toDate(),
                departure: ind?.individual?.departure || "-",
                type: "INDIVIDUAL",
            }));

            const resultIndividualOther = inscriptionListF.filter(
                (bookId) =>
                    bookId.type === TYPE_INSCRIPTION.INDIVIDUAL &&
                    [MODE_INSCRIPTION.SERVICIO, MODE_INSCRIPTION.HIJO_LS].includes(
                        bookId.individual.mode
                    )
            );

            const mookIndividualOther = resultIndividualOther.map((ind) => ({
                id: ind.id,
                ...ind.individual,
                date: ind.date.toDate().toLocaleDateString("en-GB"),
                dateOrder: ind.date.toDate(),
                departure: ind?.individual?.departure || "-",
                type: "INDIVIDUAL",
            }));

            if (isMixedMode) {
                setMixedModeRows(
                    getRowsGroupalsIndividuals(mookGroupCamp, mookIndividualCamp)
                );
            }

            setIndividualCamp(mookIndividualCamp);

            setIndividualLeader(mookIndividualLeader);

            setIndividualOther(mookIndividualOther);
        };
        // segunda parte del truco ejecutar la funcion asincronica
        getInscriptos();

        // array vacio, se ejecuta cuando se monta <app />
    }, [refresh]);

    if (!hasRows) {
        return <div style={{width: "700px", height: "100%", background: "none"}}>
            <Skeleton active round/>
        </div>
    }

    return (
        <InscriptionCenterContainer>
            <div className="flex flex-col">
                <TopContainer hasRows={hasRows}>
                    <BackButtonContainer onClick={resetTableSelected}>
                        <IconBackButton src={icon}></IconBackButton>
                    </BackButtonContainer>
                </TopContainer>
                <div className="flex justify-center">
                    <TitleUpdateContainer>
                        <Title level={5}> Campamento: {camp} </Title>
                        <ButtonSelect onClick={handleOnClickUpdate} className="bg-amber-300">
                            Actualizar
                        </ButtonSelect>
                    </TitleUpdateContainer>
                    <InscriptionCenterQuantityContainer>
                        <Card className="py-4 m-1">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                                <p className="text-tiny uppercase font-bold">Acampantes:</p>
                                <h4 className="font-bold text-large">{isMixedMode ? mixedModeRows.length : individualCamp.length}</h4>
                            </CardHeader>
                        </Card>
                        <Card className="py-4 m-1">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                                <p className="text-tiny uppercase font-bold">Líderes:</p>
                                <h4 className="font-bold text-large">{individualLeader.length}</h4>
                            </CardHeader>
                        </Card>
                        <Card className="py-4 m-1">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                                <p className="text-tiny uppercase font-bold">Otros:</p>
                                <h4 className="font-bold text-large">{individualOther.length}</h4>
                            </CardHeader>
                        </Card>
                        <Card className="py-4 m-1">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                                <p className="text-tiny uppercase font-bold">Bus IDA:</p>
                                <h4 className="font-bold text-large">{busQuantityGoing}</h4>
                            </CardHeader>
                        </Card>
                        <Card className="py-4 m-1">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                                <p className="text-tiny uppercase font-bold">Bus VUELTA:</p>
                                <h4 className="font-bold text-large">{busQuantityReturn}</h4>
                            </CardHeader>
                        </Card>
                    </InscriptionCenterQuantityContainer>
                    <InscriptionCenterQuantityContainer>
                        <Card className="py-4 m-4 bg-amber-300">
                            <CardHeader className="pb-0 pt-2 px-4 flex-col items-start">
                                <p className="text-tiny uppercase font-bold">Total asistentes:</p>
                                <h4 className="font-bold text-large">{
                                    (isMixedMode ? mixedModeRows.length : individualCamp.length) +
                                    individualLeader.length +
                                    individualOther.length
                                }</h4>
                            </CardHeader>
                        </Card>
                    </InscriptionCenterQuantityContainer>
                </div>
                <div className="flex flex-col items-center align-center p-4">
                    <div className="p-2 text-center">
                        <Title level={5}> Acampantes </Title>
                        <CSVLink data={isMixedMode ? mixedModeRows : individualCamp}>
                            Descargar CSV Acampantes
                        </CSVLink>
                    </div>
                    <div>
                        <Table
                            columns={isMixedMode ? columnsMixed : columnsIndividual}
                            dataSource={isMixedMode ? mixedModeRows : individualCamp}
                            size="small"
                            pagination={false}
                        />
                    </div>
                    <div className="p-2 text-center">
                        <Title level={5}> Líderes </Title>
                        <CSVLink data={individualLeader}>Descargar CSV Líderes</CSVLink>
                    </div>
                    <Table
                        columns={columnsIndividual}
                        dataSource={individualLeader}
                        size="small"
                    />
                    <div className="p-2 text-center">
                        <Title level={5}> Otros </Title>
                        <CSVLink data={individualOther}>Descargar CSV Otros</CSVLink>
                    </div>
                    <Table
                        columns={columnsIndividual}
                        dataSource={individualOther}
                        size="small"
                    />
                </div>
            </div>
        </InscriptionCenterContainer>
    );
}

export default InscriptionCenter;
