import styled from "styled-components";
import {device} from "../../../utils/device";

const ContentHome = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  padding-top: 20px;
  padding-bottom: 40px;
  min-height: 500px;
  @media ${device.tablet} {
    width: 90%;
  }
`;

export default ContentHome;
