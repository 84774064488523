import NavBar from "./components/NavBar/NavBar";
import {React, useEffect} from "react";
import {Routes, BrowserRouter as Router, Route} from "react-router-dom";
import {CartContext} from "./context/cartContext";
import "antd/dist/antd.css";
import Home from "./components/Home/Home";
import styled from "styled-components";
import Footer from "./components/Footer/Footer";
import GlobalStyleSheet from "./styles";
import About from "./pages/About/About";
import Hash from "./modules/inscriptionCenter/components/Hash";
import './newStyles.css'
import {NextUIProvider} from "@nextui-org/react";
import InscriptionManager from "./modules/inscription/components/InscriptionType";

const RouterContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0;
  justify-content: space-between;
  height: 100vh;
`;

const images = [
    {src: '../src/assets/images/alfa2024placa.png'},
    {src: '../src/assets/images/previewCardGroupal.png'},
    {src: '../src/assets/images/previewGeneralCard.png'}
];

function App() {
    const preLoadImages = () => {
        return (
            <div className="hidden">
                {images.map((img) => (
                    <img src={img.src} alt={img.src}/>
                ))}
            </div>
        )
    }

// Preload images
    useEffect(() => {
        const imgs = images.map((img) => new Image());
        imgs.forEach((img) => {
            img.src = img.src;
        });
    }, []);

    return (
        <CartContext.Provider>
            <NextUIProvider>
                <GlobalStyleSheet/>
                <RouterContainer>
                    <Router>
                        <NavBar/>
                        <Routes>
                            <Route
                                path="/"
                                exact
                                element={
                                    <Home/>
                                }>
                            </Route>
                            <Route
                                exact
                                path="/about"
                                element={
                                    <About/>
                                }>
                            </Route>
                            <Route
                                exact path="/inscription"
                                element={
                                    <InscriptionManager/>
                                }>
                            </Route>
                            <Route
                                exact
                                path="/gestion" element={
                                <Hash/>
                            }>
                            </Route>
                        </Routes>
                    </Router>
                    <Footer></Footer>{" "}
                </RouterContainer>
            </NextUIProvider>
            {preLoadImages()}
        </CartContext.Provider>
    );
}

export default App;
