import styled from "styled-components";

const TitleCountContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px;
  height: 100%;
`;

export default TitleCountContainer;
