import { Result, Button, Alert } from "antd";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import { Badge, Card, Tag } from "antd";
import TagButtonContainer from "./styled-components/TagButtonContainer";

export default function SuccesSendReinscription({
  inscriptionPrice,
  email,
  mercadoPagoLink,
  statusPayment,
}) {
  useEffect(() => {
    handlePayments();
    handleTitlePayment1();
    handleTitlePayment2();
  }, [email, inscriptionPrice]);

  const handleTitle = () => {
    return "Reinscripción recibida.";
  };
  const handleSubtitle = () => {
    return "Te envíaremos un mail con los datos de tu reinscripción.";
  };
  const handleAlertMessage = () => {
    if (statusPayment)
      return "* Como el encuentro es solamente hasta el domingo y vos habías pagado hasta el lunes, el día del encuentro te devolveremos la diferencia.";
    else return "* Plazo para pago finaliza en 72hs.";
  };

  if (!statusPayment) {
  }

  const handleTitlePayment1 = () => {
    if (!statusPayment) {
      const titleModal = "Total: $";
      return titleModal + inscriptionPrice?.price;
    } else {
      return "¡Ya hiciste tu pago!";
    }
  };

  const handleIva = (price) => {
    const iva = price * 0.1;
    return price + iva;
  };

  const handleTitlePayment2 = () => {
    const titleModal = "Total: $";
    return <div>{titleModal + handleIva(inscriptionPrice?.price)}</div>;
  };

  const handlePayments = () => {
    if (!statusPayment) {
      return (
        <div style={{ padding: "15px" }}>
          <Badge.Ribbon text="Abitab" color="volcano">
            <Card title={handleTitlePayment1()} size="small">
              Te enviamos un mail a: <b>{email}</b> con la información de pago
              por Abitab.
              <div style={{ padding: "20px" }}>
                <Tag color="orange"> {handleAlertMessage()}</Tag>
              </div>
            </Card>
          </Badge.Ribbon>
          <div style={{ padding: "10px" }}></div>
          <Badge.Ribbon text="Mercado Pago" color="blue">
            <Card title={handleTitlePayment2()} size="small">
              <TagButtonContainer>
                <div style={{ padding: "5px" }}>
                  <Tag color="cyan"> 10% Recargo</Tag>
                </div>
                <Button type="primary" href={mercadoPagoLink}>
                  Pagar online (MercadoPago)
                </Button>
              </TagButtonContainer>
            </Card>
          </Badge.Ribbon>
        </div>
      );
    } else {
      return (
        <div style={{ padding: "15px" }}>
          <Badge.Ribbon text="Pago" color="volcano">
            <Card title={handleTitlePayment1()} size="small">
              <div style={{ padding: "20px" }}>
                {handleAlertMessage()}
                {/* <Tag color="orange"> </Tag> */}
              </div>
            </Card>
          </Badge.Ribbon>
          <div style={{ padding: "10px" }}></div>
          <Link to="/">
            <Button type="primary">Listo</Button>
          </Link>
        </div>
      );
    }
  };
  return (
    <div>
      <Result
        status="success"
        title={handleTitle()}
        subTitle={handleSubtitle()}
        style={{ padding: "0px" }}
        extra={[handlePayments()]}
      />
    </div>
  );
}
