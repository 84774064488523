import {React} from "react";
import logo from "../../assets/icons/alfa-logo.png";
import CardWidget from "../CardWidget/CardWidget";
import HeaderHomeContainer from "../Home/styled-components/HeaderHomeContainer";
import SocialsContainer from "../Home/styled-components/SocialsContainer";
import youtube from "../../assets/icons/youtube.svg";
import instagram from "../../assets/icons/instagram.svg";
import {useNavigate} from "react-router-dom";


function NavBar() {
    const navigateTo = useNavigate();
    const openSocialUrl = (url) => {
        window.open(url, "_blank", "noreferrer");
    };

    return (
        <HeaderHomeContainer>
            <div className="cursor-pointer" onClick={() => navigateTo("/")}>
                <CardWidget icon={logo}/>
            </div>
            <SocialsContainer>
                <img
                    src={youtube}
                    alt="youtube"
                    onClick={() => openSocialUrl('https://youtube.com/eventosjuveniles')}
                />
                <div
                    style={{margin: "0px 10px"}}
                    onClick={() => openSocialUrl('https://youtube.com/eventosjuveniles')}
                >Youtube
                </div>
                <img
                    src={instagram}
                    alt="youtube"
                    onClick={() => openSocialUrl('https://www.instagram.com/evjuveniles')}
                />
                <div onClick={() => openSocialUrl('https://www.instagram.com/evjuveniles')}>
                    Instagram
                </div>
            </SocialsContainer>
        </HeaderHomeContainer>
    );
}

export default NavBar;
