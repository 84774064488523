import styled from "styled-components";
import {device} from "../../../utils/device";

// Styled component named StyledButton
const TitleHomeContainer = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  color: white;
  text-align: center;
  padding: 5px;
   @media ${device.tablet} {
     padding-top: 40px;
  }
  && p {
    color: black;
    margin: 0;
  }
`;

export default TitleHomeContainer;
