import {React, useState} from "react";
import InscriptionManagerContainer from "./styled-components/InscriptionManagerContainer";
import {
    Divider,
    Badge,
} from "antd";
import Wizard from "../Wizard";
import BadgesContainer from "./styled-components/BadgesContainer";
import imageGroupal from "../../../../assets/images/previewCardGroupal.png";
import imageGeneral from "../../../../assets/images/previewGeneralCard.png";
import TypeTitle from "./styled-components/TypeTitle";
import BadgeSubtitle from "./styled-components/BadgeSubtitle";
import CardSelectionType from "./CardSelectionType";

const TYPE = {
    INDIVIDUAL: "INDIVIDUAL",
    GROUP: "GROUP",
};

function InscriptionManager() {


    const [wizzardActive, setWizzardActive] = useState("");

    return (
        <InscriptionManagerContainer className="md:h-full">
            <div className="flex flex-col md:flex-row md:justify-center md:items-center w-full h-full">
                <div>
                    {wizzardActive === TYPE.INDIVIDUAL &&
                        <TypeTitle className="text-2xl md:text-3xl md:mx-14" level={5}>ALFA 2024</TypeTitle>
                    }
                    {!wizzardActive ? (
                        <BadgeSubtitle>
                            <Badge
                                color={"purple"}
                                text={"Selecioná el tipo de inscripción"}
                            />
                        </BadgeSubtitle>
                    ) : (
                        <BadgeSubtitle>
                            {wizzardActive === TYPE.INDIVIDUAL &&
                                <Badge
                                    color={"purple"}
                                    text={"Si tenés dudas escribínos a nuestras redes."}
                                />
                            }
                        </BadgeSubtitle>
                    )}
                    <Divider></Divider>
                </div>
                {!wizzardActive ? (
                    <div className="p-4">
                        <BadgesContainer>
                            <CardSelectionType
                                image={imageGeneral}
                                onClick={() =>
                                    setWizzardActive(TYPE.INDIVIDUAL)
                                }
                                title="GENERAL"
                                subtitle="Acampante, Líder,
                                Servicio"
                                actionText="General"
                            />
                            <CardSelectionType
                                image={imageGroupal}
                                onClick={() =>
                                    setWizzardActive(TYPE.GROUP)
                                }
                                title="GRUPAL"
                                subtitle="Inscribir a un grupo de acampantes"
                                actionText="Grupal"
                            />
                        </BadgesContainer>
                    </div>
                ) : (
                    <Wizard type={wizzardActive}></Wizard>
                )}
            </div>
        </InscriptionManagerContainer>
    );
}

export default InscriptionManager;
