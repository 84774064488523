import { CHURCHS } from "../../inscription/constants";

export const columnsIndividual = [
  {
    title: "Ci",
    dataIndex: "ci",
  },
  {
    title: "Nombre",
    dataIndex: "name",
  },
  {
    title: "Iglesia",
    dataIndex: "iglesia",
    key: "iglesia",
    filters: CHURCHS.map((text) => Object.assign({}, { text, value: text })),
    onFilter: (value, record) => record.iglesia.indexOf(value) === 0,
  },
  {
    title: "Celular",
    dataIndex: "celular",
  },
  {
    title: "Dias",
    dataIndex: "dias",
  },
  {
    title: "Bus",
    dataIndex: "bus",
    filters: [
      {
        text: "Ida",
        value: "Ida",
      },
      {
        text: "Vuelta",
        value: "Vuelta",
      },
      {
        text: "No",
        value: "No",
      },
    ],
    onFilter: (value, record) => record.bus.indexOf(value) >= 0,
  },
  {
    title: "Sexo",
    dataIndex: "sexo",
    filters: [
      {
        text: "Hombre",
        value: "Hombre",
      },
      {
        text: "Mujer",
        value: "Mujer",
      },
    ],
    onFilter: (value, record) => record.sexo.indexOf(value) === 0,
  },
  {
    title: "Edad",
    dataIndex: "edad",
  },
  {
    title: "Modo",
    dataIndex: "mode",
  },
  {
    title: "Salida Bus",
    dataIndex: "departure",
  },
  {
    title: "Comida/Medicacion",
    dataIndex: "comment",
    width: "150px",
  },
  {
    title: "Inscripción",
    dataIndex: "date",
  },
  {
    title: "Id",
    dataIndex: "id",
  },
  {
    title: "Ordenar por Fecha",
    dataIndex: "dateOrder",
    sorter: (a, b) => a.dateOrder - b.dateOrder,
    sortDirections: ["ascend"],
  },
];

export const columnsMixed = [
  {
    title: "Nombre",
    dataIndex: "name",
  },
  {
    title: "Iglesia",
    dataIndex: "iglesia",
    filters: CHURCHS.map((text) => Object.assign({}, { text, value: text })),
    onFilter: (value, record) => record.iglesia.indexOf(value) === 0,
  },
  {
    title: "Dias",
    dataIndex: "dias",
  },
  {
    title: "Bus",
    dataIndex: "bus",
    filters: [
      {
        text: "Ida",
        value: "Ida",
      },
      {
        text: "Vuelta",
        value: "Vuelta",
      },
      {
        text: "No",
        value: "No",
      },
    ],
    onFilter: (value, record) => record.bus.indexOf(value) >= 0,
  },
  {
    title: "Sexo",
    dataIndex: "sexo",
    filters: [
      {
        text: "Hombre",
        value: "Hombre",
      },
      {
        text: "Mujer",
        value: "Mujer",
      },
    ],
    onFilter: (value, record) => record.sexo.indexOf(value) === 0,
  },
  {
    title: "Edad",
    dataIndex: "edad",
  },
  {
    title: "Modo",
    dataIndex: "mode",
  },
  {
    title: "Salida Bus",
    dataIndex: "departure",
  },
  {
    title: "Inscripción",
    dataIndex: "date",
  },
  {
    title: "Comentarios",
    dataIndex: "comment",
    width: "150px",
  },
  {
    title: "Responsable",
    dataIndex: "owner",
  },
  {
    title: "Campamento",
    dataIndex: "camp",
  },
  {
    title: "Id",
    dataIndex: "id",
  },
  {
    title: "Ordenar por Fecha",
    dataIndex: "dateOrder",
    sorter: (a, b) => a.dateOrder - b.dateOrder,
    sortDirections: ["ascend"],
  },
];
