import { MODE, SEDE } from "../constants";

const isValidModeFullFamily = (inscription) => {
  return (
    (inscription?.father?.dias === MODE.FULL_INSCRIPTION ||
      inscription?.father?.dias === MODE.FULL_INSCRIPTION_WITH_VIERNES) &&
    (inscription?.mother?.dias === MODE.FULL_INSCRIPTION ||
      inscription?.mother?.dias === MODE.FULL_INSCRIPTION_WITH_VIERNES)
  );
};

export default isValidModeFullFamily;
