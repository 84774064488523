import { React } from "react";
import { Card, Descriptions, Divider } from "antd";
import { Typography } from "antd";
import AboutContainer from "./styled-components/AboutContainer";
import { IMAGES } from "./images";
import DescriptionContainer from "./styled-components/DescriptionContainer";

const { Title } = Typography;

const gridStyle = {
  width: "400px",
  textAlign: "center",
  display: "flex",
};

function About() {
  return (
    <>
      <Title level={4} style={{ padding: "15px" }}>
        Eventos Juveniles
      </Title>
      <DescriptionContainer>
        Eventos Juveniles es la comisión encargada de organizar los eventos para
        jóvenes y adolescentes de las Iglesias Cristianas Evangélicas de los
        Hermanos Libres.
      </DescriptionContainer>
      <Divider></Divider>
      <AboutContainer>
        {IMAGES.map((img) => (
          <Card.Grid style={gridStyle}>
            <img style={{ width: "100%" }} src={img}></img>
          </Card.Grid>
        ))}
      </AboutContainer>
    </>
  );
}

export default About;
