import styled from "styled-components";
import {device} from "../../../../../utils/device";

const InscriptionManagerContainer = styled.div`
  align-content: center;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  background: linear-gradient(161.91deg, rgb(231, 201, 158) 4.96%, rgb(181, 159, 97) 83.92%);
  @media ${device.tablet} {
    background: none;
  }
`;

export default InscriptionManagerContainer;
