import styled from "styled-components";

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 20px;
  ${(props) => !props.hasRows && "position:  absolute"};
  ${(props) => !props.hasRows && "left: 165px"};
  ${(props) => !props.hasRows && "top: 100px"};
`;

export default TopContainer;
