import styled from "styled-components";
import {device} from "../../../../../utils/device";

const FormContentContainer = styled.div`
  @media ${device.tablet} {
    max-height: 400px;
  }
`;

export default FormContentContainer;
