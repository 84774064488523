import styled from "styled-components";

const UploadButton = styled.input`
  width: 100%;
  height: 52px;
  &::before {
    margin-top: 5px;
    box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.2);
    background-color: rgb(138 220 84);
    color: rgba(0, 0, 0, 0.85);
    font-size: 0.85em;
    font-weight: bold;
    line-height: normal;
    letter-spacing: normal;
    content: 'Subir';
    display: flex;
    align-items: center;
    justify-content: center;
    border-style: none;
    border-radius: 6px;
    height: 45px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: ${(props) => (props.disabled ? `default` : `pointer`)}};
    ${(props) =>
    props.disabled &&
    `opacity: 0.3; -webkit-filter: grayscale(90%); filter: grayscale(90%);`}};
  }
  &::-webkit-file-upload-button {
    display: none;
  }
  
`;

export default UploadButton;
