import {React, useState} from "react";
import HashContainer from "./styled-components/HashContainer";
import {collection, getDoc, doc, addDoc, Timestamp} from "firebase/firestore";
import {getData} from "../../../../firebase";
import {Form, Typography, Divider} from "antd";
import InputStyled from "../../../../components/Input";
import InscriptionCenter from "../../InscriptionCenter";
import SelectTable from "../SelectTable";
import {Button, Input} from "@nextui-org/react";

function Hash() {
    const {Title} = Typography;

    const [hash, setHash] = useState("");
    const [hashValid, setHashValid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [tableOnFilter, setTableOnFilter] = useState("");

    const onValidateHash = async () => {
        setLoading(true);
        const snap = await getDoc(doc(getData(), "hash", `${hash}`));

        if (snap.exists()) {
            setHashValid(true);
        } else {
            setHashValid(false);
        }
        setLoading(false);
    };

    console.log("HASH", hash);

    return (
        <HashContainer>
            {!hashValid ? (
                <>
                    <Title level={5}>Gestión</Title>
                    <Divider></Divider>
                    <div style={{padding: "5px"}}>
                        <div className="flex align-center items-center p-4">
                            <Input
                                type="password"
                                label="Contraseña"
                                placeholder="Ingresar contraseña "
                                value={hash || ""}
                                defaultValue={hash || ""}
                                className="max-w-[220px] p-2"
                                onChange={({target}) => {
                                    setHash(target.value);
                                }}
                            />
                            {/*<Button*/}
                            {/*    type="primary"*/}
                            {/*    disabled={!hash}*/}
                            {/*    onClick={onValidateHash}*/}
                            {/*    loading={loading}*/}
                            {/*>*/}
                            {/*    Ingresar*/}
                            {/*</Button>*/}
                            <Button
                                color="warning"
                                isDisabled={!hash}
                                onClick={onValidateHash}
                                isLoading={loading}
                            >
                                Ingresar
                            </Button>
                        </div>
                    </div>
                </>
            ) : (
                <SelectTable></SelectTable>
            )}
        </HashContainer>
    );
}

export default Hash;
