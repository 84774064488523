import styled from "styled-components";

const SocialsContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
`;

export default SocialsContainer;
