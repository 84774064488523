import styled from "styled-components";
import {device} from "../../../utils/device";

const HeaderHomeContainer = styled.div`
  display: flex;
  width: 90%;
  @media ${device.tablet} {
    width: 70%;
  }
  margin-top: 20px;
  justify-content: space-between;
  align-items: center;
`;

export default HeaderHomeContainer;
