import styled from "styled-components";
import {device} from "../../../../../utils/device";

// Styled component named StyledButton
const BadgeSubtitle = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 2em;
  text-align: center;
`;

export default BadgeSubtitle;
