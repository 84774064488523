import { MODE, TYPE_INSCRIPTION } from "../constants";
import getTypeInscription from "./getTypeInscription";
import isValidModeFullFamily from "./isValidModeFullFamily";

const fetchMercadoPago = (inscription) => {
  if (getTypeInscription(inscription) === TYPE_INSCRIPTION.INDIVIDUAL) {
    const { individual } = inscription;
    if (
      individual.dias === MODE.FULL_INSCRIPTION ||
      MODE.FULL_INSCRIPTION_WITH_VIERNES
    )
      if (individual.bus) return "https://mpago.la/2YYBtrn";
      else return "https://mpago.la/2dicnXR";
  }

  if (getTypeInscription(inscription) === TYPE_INSCRIPTION.MATRIMONIAL) {
    if (isValidModeFullFamily(inscription)) {
      /* if (inscription?.children?.childrenQuantityMayor === 0) {
        if (inscription?.family?.bus) return "https://mpago.la/2c2y2f5";
        else return "https://mpago.la/1Gq8iHT";
      } else {
        switch (inscription?.children?.childrenQuantityMayor) {
          case 1:
            return "https://mpago.la/324gZaf";
          case 2:
            return "https://mpago.la/1dScAM7";
          case 3:
            return "https://mpago.la/2g8MnTX";
          case 4:
            return "https://mpago.la/1gxkyCf";
          default:
            return "";
        }
      } */

      if (inscription?.children?.childrenQuantityMayor === 0) {
        if (inscription?.family?.bus) return "https://mpago.la/2c2y2f5";
        else return "https://mpago.la/1Gq8iHT";
      } else {
        switch (inscription?.children?.childrenQuantityMayor) {
          case 0:
            if (inscription?.family?.bus) return "https://mpago.la/2c2y2f5";
            else return "https://mpago.la/1Gq8iHT";
          case 1:
            if (inscription?.family?.bus) return "https://mpago.la/2hsuDwX";
            else return "https://mpago.la/324gZaf";
          case 2:
            if (inscription?.family?.bus) return "https://mpago.la/2TqSeUC";
            else return "https://mpago.la/1dScAM7";
          case 3:
            if (inscription?.family?.bus) return "https://mpago.la/2XETv9m";
            else return "https://mpago.la/2g8MnTX";
          case 4:
            if (inscription?.family?.bus) return "https://mpago.la/2rXKGUU";
            else return "https://mpago.la/1gxkyCf";
          default:
            return "";
        }
      }
    }
    return "";
  }
};

export default fetchMercadoPago;
