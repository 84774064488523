import {useEffect, useState} from "react";
import {
    MODE,
    CAMPS,
    TYPE_OPTIONS,
    BUS_FULL,
    CHURCHS,
} from "../../../../constants";
import {Select, SelectItem} from "@nextui-org/react";
import InputNext from "../../styled-components/InputNext";

function CampInformation({formState, setFormState, setBusIsNotAviable}) {
    const [otherChurch, setOtherChurch] = useState(false);
    const [listChurch, setListChurch] = useState(new Set([formState?.individual?.iglesia || '']));
    const [listCampsSelected, setListCampsSelected] = useState(new Set([formState?.individual?.camp || '']));
    const [listDaysSelected, setListDaysSelected] = useState(new Set([formState?.individual?.dias || '']));
    const [listInscriptionTypeSelected, setListInscriptionTypeSelected] = useState(new Set([formState?.individual?.mode || '']));

    const getCampStatusBus = () => {
        const {camps} = BUS_FULL;
        let encontre = false;
        for (let i = 0; i < camps.length && !encontre; i++) {
            if (camps[i].value === formState?.individual?.camp) encontre = true;
        }
        setBusIsNotAviable(encontre);
        setFormState({
            ...formState.individual,
            bus: encontre ? "No" : "",
            departure: null,
        });
        return encontre;
    };


    // eslint-disable-next-line react-hooks/rules-of-hooks
    useEffect(() => {
        getCampStatusBus();
    }, [formState?.individual?.camp]);

    const handleSelectionChangeChurch = (e) => {
        const churchSelected = e.target.value;
        setListChurch(new Set([churchSelected]));

        if (churchSelected !== "Otra")
            setFormState({
                ...formState.individual,
                iglesia: churchSelected,
            });
        else {
            setFormState({
                ...formState.individual,
                iglesia: "",
            });
        }
        setOtherChurch(churchSelected === "Otra");
    };

    const handleSelectionChangeCamp = (e) => {
        const campSelected = e.target.value;
        setListCampsSelected(new Set([campSelected]));

        setFormState({
            ...formState.individual,
            camp: campSelected,
        });
    };

    const handleSelectionChangeDays = (e) => {
        const daysSelected = e.target.value;
        setListDaysSelected(new Set([daysSelected]));
        setFormState({
            ...formState.individual,
            dias: daysSelected !== 'todos' ? parseInt(daysSelected, 10) : daysSelected,
        });
    };

    const handleSelectionChangeInscriptionType = (e) => {
        const inscriptionType = e.target.value;
        setListInscriptionTypeSelected(new Set([inscriptionType]));

        setFormState({
            ...formState.individual,
            mode: inscriptionType
        });
    };


    const getDisabledCamps = () => {
        const disabledCamps = CAMPS.filter((camp) => !camp.aviable);
        return disabledCamps.map((item) => item.name);
    }

    return (
        <div className="flex flex-col">
            <Select
                isRequired
                label="Iglesia"
                placeholder="Seleccionar iglesia"
                className="my-3"
                onChange={handleSelectionChangeChurch}
                selectedKeys={listChurch}
            >
                {CHURCHS.map((church) => (
                    <SelectItem key={church} value={church}>
                        {church}
                    </SelectItem>
                ))}
            </Select>

            {otherChurch && (
                <InputNext
                    defaultValue={formState?.individual?.iglesia}
                    type="text"
                    variant="flat"
                    label="Nombre de la Iglesia"
                    onChange={({target}) => {
                        setFormState({
                            ...formState.individual,
                            iglesia: target.value,
                        });
                    }}
                />
            )}

            <Select
                isRequired
                label="Campamento"
                className="my-3"
                onChange={handleSelectionChangeCamp}
                // defaultSelectedKeys={[formState?.individual?.sexo]}
                selectedKeys={listCampsSelected}
                disabledKeys={getDisabledCamps()}
            >
                {CAMPS?.map((camp) => (
                    <SelectItem key={camp.name} value={camp.name}>
                        {camp.name}
                    </SelectItem>
                ))}
            </Select>

            <Select
                isRequired
                label="¿Qué días vas?"
                className="my-3"
                onChange={handleSelectionChangeDays}
                selectedKeys={listDaysSelected}
            >
                {MODE?.map((day) => (
                    <SelectItem key={day.id} value={day.id}>
                        {day.value}
                    </SelectItem>
                ))}
            </Select>

            <Select
                isRequired
                label="¿Asistis cómo?"
                className="my-3"
                onChange={handleSelectionChangeInscriptionType}
                selectedKeys={listInscriptionTypeSelected}
            >
                {TYPE_OPTIONS?.map((type) => (
                    <SelectItem key={type.name} value={type.name}>
                        {type.name}
                    </SelectItem>
                ))}
            </Select>
        </div>
    );
}

export default CampInformation;
