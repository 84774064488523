import {initializeApp} from "firebase/app";
import {getFirestore} from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCLU_1n8MeE3BAEibcQbRdKVcgu9gzB6t8",
    authDomain: "alfa-2024.firebaseapp.com",
    projectId: "alfa-2024",
    storageBucket: "alfa-2024.appspot.com",
    messagingSenderId: "157570301125",
    appId: "1:157570301125:web:a1ae5e9284771855b784ac"
};

// Initialize Firebase y lo pongo en app
const app = initializeApp(firebaseConfig);

// getData devuelve la conexion con firestore a mi app
export const getData = () => getFirestore(app);
