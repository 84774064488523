import {React, useEffect, useState} from "react";
import fileToDownload from "../../../../assets/files/bulk_orders_template.xlsx";
import DownloadButton from "../EditFormGroupal/styled-components/DownloadButton";
import ButtonsBulkContainer from "../EditFormGroupal/styled-components/ButtonsBulkContainer";
import {Tag, Badge} from "antd";
import {MODE, CAMPS_BULK, DEPARTURE_OPTIONS, BUS_FULL, CHURCHS, CAMPS} from "../../constants";
import {CheckCircleOutlined} from "@ant-design/icons";
import * as XLSX from "xlsx";
import ButtonFormContainer from "../EditFormGroupal/styled-components/ButtonFormContainer";
import UploadButton from "./styled-components/UploadButton";
import FormContentContainer from "../EditFormGroupal/styled-components/FormContentContainer";
import LoadFileContainer from "./styled-components/LoadFileContainer";
import {Button, Chip, SelectItem, Select as SelectNext} from "@nextui-org/react";
import ExampleFileModal from "./components/ExampleFileModal";
import InputNext from "../EditFormIndividual/styled-components/InputNext";

function EditFormGroupal({currentStep, setStep, formState, setFormState}) {
    const [formValid, setFormValid] = useState(false);
    const [viewModalExampleFile, setViewModalExampleFile] = useState(false);
    const [items, setItems] = useState(formState?.groupal || []);
    const [fileName, setFileName] = useState(formState?.groupal?.length > 0 ? formState?.groupal[0]?.fileName : "");
    const [otherChurch, setOtherChurch] = useState(false);
    const [listChurch, setListChurch] = useState(new Set([!!formState?.groupal ? formState?.groupal[0]?.iglesia : '']));
    const [listCampsSelected, setListCampsSelected] = useState(new Set([!!formState?.groupal ? formState?.groupal[0]?.camp : '']));
    const [listDepartureSelected, setListDepartureSelected] = useState(new Set([!!formState?.groupal ? formState?.groupal[0]?.departure : '']));


    const [church, setChurch] = useState(
        !!formState?.groupal ? formState?.groupal[0]?.iglesia : ""
    );
    const [owner, setOwner] = useState(
        !!formState?.groupal ? formState?.groupal[0]?.owner : ""
    );
    const [camp, setCamp] = useState(
        !!formState?.groupal ? formState?.groupal[0]?.camp : ""
    );
    const [departure, setDeparture] = useState(
        !!formState?.groupal ? formState?.groupal[0]?.departure : ""
    );

    const [busIsNotAviable, setBusIsNotAviable] = useState(false);


    console.log("ITEMS", items);
    console.log("NAME", fileName);

    const fileLoaded = !!items && fileName;

    const readExcel = (file) => {
        setFileName(file.name);
        const promise = new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsArrayBuffer(file);

            fileReader.onload = (e) => {
                const bufferArray = e.target.result;

                const wb = XLSX.read(bufferArray, {type: "buffer"});

                const wsname = wb.SheetNames[0];

                const ws = wb.Sheets[wsname];

                const data = XLSX.utils.sheet_to_json(ws);

                resolve(data);
            };

            fileReader.onerror = (error) => {
                reject(error);
            };
        });

        promise.then((d) => {
            const options = d.map(function (row) {
                // This function defines the "mapping behaviour". name and title
                // data from each "row" from your columns array is mapped to a
                // corresponding item in the new "options" array

                return {
                    name: row.nombre_apellido,
                    edad: row.edad,
                    sexo: row.sexo,
                    comment: row.comentarios_comida_medicacion,
                };
            });
            setItems(options);
        });
    };

    const onBackButton = () => {
        setStep(currentStep - 1);
    };

    const handleOnClick = () => {
        if (currentStep === 1) {
            const mookGroupal = items.map((group) => ({
                ...group,
                bus: busIsNotAviable ? "No" : "Ida y Vuelta",
                dias: MODE[0].id,
                mode: "Acampante",
                iglesia: church,
                comment: !!group.comment ? group.comment : "ninguno",
                owner: owner,
                camp: camp,
                departure: departure,
                fileName: fileName
            }));
            setFormState([...mookGroupal]);
        }
        setStep(currentStep + 1);
    };

    const areFieldsValid = () => {
        if (currentStep === 0) {
            return !!fileName;
        }
        if (currentStep === 1) {
            console.log(church, camp, departure, owner, busIsNotAviable);
            return (
                !!church && !!camp && (busIsNotAviable ? true : !!departure) && !!owner
            );
        }
    };

    const getCampStatusBus = () => {
        const {camps} = BUS_FULL;
        let encontre = false;
        for (let i = 0; i < camps.length && !encontre; i++) {
            if (camps[i].value === camp) encontre = true;
        }
        setBusIsNotAviable(encontre);
        return encontre;
    };

    const handleSelectionChangeChurch = (e) => {
        const churchSelected = e.target.value;
        setListChurch(new Set([churchSelected]));

        if (churchSelected !== "Otra")
            setChurch(churchSelected);
        else {
            setChurch("");
        }
        setOtherChurch(churchSelected === "Otra");
    };

    const handleSelectionChangeCamp = (e) => {
        const campSelected = e.target.value;
        setListCampsSelected(new Set([campSelected]));

        setCamp(campSelected);
    };

    const getDisabledCamps = () => {
        const disabledCamps = CAMPS_BULK.filter((camp) => !camp.aviable);
        return disabledCamps.map((item) => item.name);
    }

    const handleSelectionChangeDeparture = (e) => {
        const departureSelected = e.target.value;
        setListDepartureSelected(new Set([departureSelected]));

        setDeparture(departureSelected);
    };

    const closeExampleFileModal = () => {
        setViewModalExampleFile(false);
    };

    useEffect(() => {
        setFormValid(areFieldsValid());
    }, [formState, currentStep, items, church, camp, departure, owner]);

    useEffect(() => {
        getCampStatusBus();
    }, [camp]);

    return (
        <FormContentContainer>
            <ExampleFileModal
                visible={viewModalExampleFile}
                onCloseModal={closeExampleFileModal}
            />
            {currentStep === 0 && (
                <LoadFileContainer>
                    <div
                        style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                            marginBottom: "5px",
                        }}
                    >
                        <ButtonsBulkContainer>
                            <div className="flex flex-col w-full">
                                <Badge
                                    color={"purple"}
                                    text={"Descarga el archivo excel"}
                                />
                            </div>
                            <DownloadButton
                                href={fileToDownload}
                                download="AlfaTemplate.xlsx"
                            >
                                Descargar
                            </DownloadButton>
                            <div className="w-full pt-8">
                                <div>
                                    <Badge
                                        color={"purple"}
                                        text={"Completá la lista"}
                                    />
                                    <Button
                                        className="ml-4"
                                        size="sm"
                                        color="secondary"
                                        onClick={() => setViewModalExampleFile(true)}>
                                        Ver ejemplo
                                    </Button>
                                </div>
                                <Badge
                                    color={"purple"}
                                    text={"Subílo para inscribir de forma masiva"}
                                />
                                <UploadButton
                                    type="file"
                                    onChange={(e) => {
                                        const file = e.target.files[0];
                                        readExcel(file);
                                    }}
                                ></UploadButton>
                            </div>
                        </ButtonsBulkContainer>
                    </div>

                    {fileLoaded && (
                        <Tag
                            style={{marginBottom: "20px"}}
                            icon={<CheckCircleOutlined/>}
                            color="success"
                        >
                            {fileName}
                        </Tag>
                    )}

                    <div className="flex flex-col items-center pt-8">
                        <Chip color="warning" variant="solid">
                            IMPORTANTE: Líderes inscribirse de manera individual
                        </Chip>
                        <div className="p-2">
                            <Chip color="warning" variant="solid">
                                NO incluirlos en esta carga grupal
                            </Chip>
                        </div>
                    </div>

                </LoadFileContainer>
            )}

            {currentStep === 1 && (
                <div className="flex flex-col content-between h-full w-1/2">
                    <div className="h-full">
                        <SelectNext
                            isRequired
                            label="Iglesia"
                            placeholder="Seleccionar iglesia"
                            className="my-3"
                            onChange={handleSelectionChangeChurch}
                            selectedKeys={listChurch}
                        >
                            {CHURCHS.map((church) => (
                                <SelectItem key={church} value={church}>
                                    {church}
                                </SelectItem>
                            ))}
                        </SelectNext>

                        {otherChurch && (
                            <InputNext
                                defaultValue={formState?.groupal[0]?.iglesia}
                                type="text"
                                variant="flat"
                                label="Nombre de la Iglesia"
                                onChange={({target}) => {
                                    setChurch(target.value);
                                }}
                            />
                        )}

                        <SelectNext
                            isRequired
                            label="Campamento"
                            className="my-3"
                            onChange={handleSelectionChangeCamp}
                            // defaultSelectedKeys={[formState?.individual?.sexo]}
                            selectedKeys={listCampsSelected}
                            disabledKeys={getDisabledCamps()}
                        >
                            {CAMPS_BULK?.map((camp) => (
                                <SelectItem key={camp.name} value={camp.name}>
                                    {camp.name}
                                </SelectItem>
                            ))}
                        </SelectNext>

                        <SelectNext
                            isRequired
                            label="¿Dónde subís?"
                            placeholder="Seleccionar lugar"
                            className="my-3"
                            isDisabled={busIsNotAviable}
                            onChange={handleSelectionChangeDeparture}
                            selectedKeys={listDepartureSelected}
                        >
                            {DEPARTURE_OPTIONS?.map((departure) => (
                                <SelectItem key={departure.name} value={departure.name}>
                                    {departure.name}
                                </SelectItem>
                            ))}
                        </SelectNext>
                        {busIsNotAviable &&
                            <Tag style={{margin: "5px 0px"}} color="red">
                                Bus sin lugares
                            </Tag>
                        }
                        <InputNext
                            defaultValue={formState?.groupal?.length > 0 ? formState?.groupal[0]?.owner : ""}
                            type="text"
                            variant="flat"
                            label="Nombre de Responsable"
                            onChange={({target}) => {
                                setOwner(target.value);
                            }}
                        />
                    </div>
                </div>
            )}

            <ButtonFormContainer>
                <div style={{padding: "0px 15px"}}>
                    <Button
                        color="primary"
                        radius="full"
                        size="md"
                        onClick={onBackButton}
                        isDisabled={currentStep === 0}
                    >Volver</Button>
                </div>
                <div style={{padding: "0px 15px"}}>
                    <Button
                        color="primary"
                        radius="full"
                        size="md"
                        onClick={handleOnClick}
                        isDisabled={!fileLoaded || !formValid}
                    >Siguiente</Button>
                </div>
            </ButtonFormContainer>
        </FormContentContainer>
    );
}

export default EditFormGroupal;
