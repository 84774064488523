import emailjs from "emailjs-com";
import { init } from "emailjs-com";
init("user_nuNXwtPyqxZAYpTkM6vzQ");

export default function ContactUs(
  inscription,
  price,
  slots,
  modeReinscription,
  errorLog
) {
  let mailToSend = {};
  let setTemplate = "";

  if (inscription?.individual) {
    setTemplate = "template_lfaaewb";
    mailToSend = {
      name: inscription?.individual?.name,
      mode: inscription?.individual?.mode,
      camp: inscription?.individual?.camp,
      iglesia: inscription?.individual?.iglesia,
      dias: inscription?.individual?.dias,
      celular: inscription?.individual?.celular,
    };
  }
  if (inscription?.groupal) {
    if (inscription?.groupal.length > 0) {
      setTemplate = "template_btw2kzh";
      mailToSend = {
        name: inscription?.groupal[0].owner,
        camp: inscription?.groupal[0].camp,
        iglesia: inscription?.groupal[0].iglesia,
        cantidad: inscription?.groupal.length,
      };
    }
  }

  if (errorLog) {
    setTemplate = "template_btw2kzh";
    mailToSend = {
      error: errorLog,
    };
  }

  emailjs.send("service_h06dhms", setTemplate, mailToSend).then(
    function (response) {
      console.log("SUCCESS!", response.status, response.text);
    },
    function (error) {
      console.log("FAILED...", error);
    }
  );
}
