import { TYPE_INSCRIPTION } from "../constants";
import getTypeInscription from "../hooks/getTypeInscription";
import getInscriptionByCi from "./getInscriptionByCi";

const onValidInscriptionExist = async (
  formState,
  modeReinscription,
  fetchTableOnInsert
) => {
  let exist = false;
  const type = getTypeInscription(formState);
  let ci;

  if (type === TYPE_INSCRIPTION.INDIVIDUAL) {
    ci = formState?.individual.ci;
    exist = await getInscriptionByCi(ci, fetchTableOnInsert);
  }
  return exist;
};

export default onValidInscriptionExist;
