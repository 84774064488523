import styled from "styled-components";

// Styled component named StyledButton
const DescriptionHome = styled.div`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 700;
  font-size: 0.8em;
  color: black;
  text-align: center;
  margin: 0;
`;

export default DescriptionHome;
