import styled from 'styled-components';

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  height: 100%;
  color: #fffff;
  ${props => props.flexEnd && 'align-self: flex-end'}
`;

export default SectionContainer;
