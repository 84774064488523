import styled from "styled-components";

const ButtonFormContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 50px;
  padding: 30px;
`;

export default ButtonFormContainer;
