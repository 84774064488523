import { CAMP, CAMP_BD, TYPE_INSCRIPTION } from "../constants";
import getTypeInscription from "./getTypeInscription";

const fetchTable = (inscription) => {
  let camp = "";
  if (getTypeInscription(inscription) === TYPE_INSCRIPTION.INDIVIDUAL) {
    camp = inscription.individual.camp;
  }

  if (getTypeInscription(inscription) === TYPE_INSCRIPTION.GROUP) {
    const { groupal } = inscription;
    camp = groupal[0].camp;
  }

  switch (camp) {
    case CAMP.CAMP_1:
      return CAMP_BD.CAMP_1;
    case CAMP.CAMP_2:
      return CAMP_BD.CAMP_2;
    case CAMP.CAMP_3:
      return CAMP_BD.CAMP_3;
    case CAMP.CAMP_4:
      return CAMP_BD.CAMP_4;
    case CAMP.CAMP_5:
      return CAMP_BD.CAMP_5;
    case CAMP.CAMP_6:
      return CAMP_BD.CAMP_6;
    case CAMP.CAMP_7:
      return CAMP_BD.CAMP_7;
    case CAMP.CAMP_8:
      return CAMP_BD.CAMP_8;
    default:
      return "";
  }
};

export default fetchTable;
